@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inknut-antiqua-light {
  font-family: "Inknut Antiqua", serif;
  font-weight: 300;
  font-style: normal;
}

.inknut-antiqua-regular {
  font-family: "Inknut Antiqua", serif;
  font-weight: 400;
  font-style: normal;
}

.inknut-antiqua-medium {
  font-family: "Inknut Antiqua", serif;
  font-weight: 500;
  font-style: normal;
}

.inknut-antiqua-semibold {
  font-family: "Inknut Antiqua", serif;
  font-weight: 600;
  font-style: normal;
}

.inknut-antiqua-bold {
  font-family: "Inknut Antiqua", serif;
  font-weight: 700;
  font-style: normal;
}

.inknut-antiqua-extrabold {
  font-family: "Inknut Antiqua", serif;
  font-weight: 800;
  font-style: normal;
}

.inknut-antiqua-black {
  font-family: "Inknut Antiqua", serif;
  font-weight: 900;
  font-style: normal;
}


header {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('components/images/girlbg.png'); */
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.justify-text {
  text-align: justify !important;
}


.each-head {
  color: #124076;
  font-size: 52px;
  font-family: "Inknut Antiqua";
  font-weight: 700;
  word-wrap: break-word
}

.each-subhead {
  color: #124076;
  font-family: "Inknut Antiqua";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.each-head2 {
  color: #487D49;

  font-family: "Inknut Antiqua";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.each-subhead2 {
  color: #487D49;
  font-family: "Inknut Antiqua";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 1000px) {
  p {
    font-size: 20px !important;
  }


}

.quotes-head {
  color: #487D49;
  font-family: "Inknut Antiqua";
  font-size: 25px !important;
  font-weight: 600 !important;

}

.quotes-headblue {
  color: #124076;
  font-family: "Inknut Antiqua";
  font-size: 25px !important;
}


@media screen and (max-width: 480px) {

  .each-head,
  .each-head2 {
    font-size: 32px !important;
  }

  .each-subhead2,
  .each-subhead {
    font-size: 20px !important;
  }

  .quotes-head,
  .quotes-headblue {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 575px) {

  .each-head,
  .each-head2 {
    font-size: 36px !important;

  }

  .each-subhead2,
  .each-subhead {
    font-size: calc(22px - (576px - 100vw) / 30);
  }

  .quotes-head,
  .quotes-headblue {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 575px) and (max-width: 640px) {

  .each-head,
  .each-head2 {
    font-size: 40px !important;
  }

  .each-subhead2,
  .each-subhead {
    font-size: 24px !important;
  }

  .quotes-head,
  .quotes-headblue {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 768px) {

  .each-head,
  .each-head2 {
    font-size: 44px !important;
  }

  .each-subhead2,
  .each-subhead {
    font-size: 26px !important;
  }

  .quotes-head,
  .quotes-headblue {
    font-size: 24px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .each-head,
  .each-head2 {
    font-size: 48px !important;
  }

  .each-subhead2,
  .each-subhead {
    font-size: 30px !important;
  }

  .quotes-head,
  .quotes-headblue {
    font-size: 26px !important;
  }
}




.image-container {
  position: relative;
}


p {
  font-size: 20px !important;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

section {
  min-height: 100vh;
  overflow: hidden;
}


.nav-head {
  color: #124076;
  font-weight: bold;
  vertical-align: middle;
  line-height: 1.8;
}

.navcont {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  color: black !important;

}

.navpaths:hover {
  color: #124076 !important;
  border-bottom: 2px solid #124076 !important;
  font-weight: 500 !important;
}

.navpaths {
  color: rgb(96, 96, 96) !important;
  font-size: 18px !important;
  border: none;
  outline: none;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .navpaths {
    font-size: 14px !important;
  }

  .nav-head {
    font-size: 16px !important;
  }
}

.navcont button:focus,
.navcont button:active,
.navcont button:hover {
  outline: none;
}

/* CSS for responsive carousel text */


.heading1 {
  font-family: "Roboto", sans-serif;
  position: absolute !important;
  color: white !important;
  width: 80% !important;
  top: 65% !important;
  left: 20% !important;
  transform: translate(-50%, -50%) !important;
  text-align: center !important;
}

/* Responsive adjustments */
@media (max-width: 1169px) {
  .heading1 {
    font-size: 2.5rem !important;
    left: 40% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }
}

@media (max-width: 991px) {
  .heading1 {
    font-size: 2rem !important;
    left: 30% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }

  .heading1 h1 {
    font-size: 1.9rem !important;
  }



}

@media (max-width: 761px) {
  .heading1 {
    font-size: 1.8rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }

  .heading1 h1 {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 640px) {
  .heading1 {

    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }
}

@media (max-width: 576px) {
  .heading1 {
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }

  .heading1 h1 {
    font-size: 1.9rem !important;
  }
}

@media (max-width: 480px) {
  .heading1 {
    width: 80% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 50% !important;
  }

  .heading1 h1 {
    font-size: 1.3rem !important;
  }
}



.overlap-group {
  position: relative;

}

.wave-left1 {
  position: absolute;
  height: 500px;
  width: auto;

}

.wave-left2 {
  position: absolute;
  height: 536px;
  width: auto;
  top: 160px;
  right: 0px;
}

footer {
  background-color: #124076;
  color: white;
}

.footer-nav {
  list-style-type: none;

}

.footer-navbutton {
  background-color: #124076;
  color: white;
  border: none;
  outline: none;
}

.social-links {
  list-style-type: none;

}


.social-links li {

  display: inline-block;
  margin-right: 20px;
}


.footer-nav {
  text-decoration: underline;
  text-decoration-color: #FFC374;
  border: 0;
  color: white;
}

.social-links li a:link,
.social-links li a:visited {
  font-size: 100%;
  color: white;
}

.footer-nav:hover,
.social-links li a:hover {
  color: #ddd;
  text-decoration: none;
}


.fb:hover {
  color: #3b5998;
}

.gl:hover {
  color: #dd4b39;
}

.tw:hover {
  color: #00aced;
}

.ig:hover {
  color: rgb(207, 28, 183);
}

.modal-90w {
  max-width: 70vw !important;
}

.custom-modal-body {
  display: flex;
  flex-direction: column;
}


@media (max-width: 960px) {
  .modal-90w {
    max-width: 95vw !important;
  }
}

.blue-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #124076;
  flex: 1;
  padding: 20px;
  color: white;
  font-size: large;
  border-radius: 10px;
}

.form-section {
  flex: 1;
  padding: 20px;
}

.blue-text {
  color: #124076 !important;
}

.green-box {
  background-color: #B8C9B9 !important;
  padding: 20px;
  border-radius: 5px;
  color: white !important;
}